.image-content-input {
    margin-bottom: 12px;
}

.image-upload {
}

.image-content {
    display: flex;
    border: 2px solid var(--cool-gray);
    border-style: dashed;
    border-radius: 8px;
    padding: 16px;
    height: auto;
}

.image-content-logo {
    display: flex;
    border: 2px solid var(--cool-gray);
    border-style: dashed;
    border-radius: 8px;
    padding: 16px;
}

.image-content img {
    margin: auto;
    max-height: 372px;
    max-width: 100%;
}
