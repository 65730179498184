body {
}

.main-sidebar .sidebar {
    background: white !important; /*#D82239*/
}
.brand-head {
    background: white !important;
}
.brand-text {
    text-decoration: none;
    color: #D82239;
}

.txt-sidebar {
    text-decoration: none;
    color: #D82239;
}

.elevation-2 {
    background: #6c757d;
}